.header-sticky {
    height: 100px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;
}   

.cursor-pointer {
    cursor: pointer;
}

.menu-list li {
    padding: 0 20px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
}

.menu-list li a {
    text-decoration: none;
    color: #000;
}



.menu-list li a {

    display: inline-block;
    padding-bottom: 5px;
    position: relative;
}


.menu-list li a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: #e20712;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.menu-list li a:hover {
    color: #e20712;
}

.menu-list li a:hover:after {
    width: 100%;
    left: 0;
}


.hero-bg {
    background: url('./assets/images/hero-bg.jpg');
    height: 900px;
    position: relative;
    background-size: cover;
}

.hero-bg .bg-overlay {
    position: absolute;
    left: 0;
    height: 900px;
    right: 0;
    top: 0;
    background: #000;
    opacity: 0.5;
}

.main {
    padding-top: 100px;
}

.hero-contain {
    position: relative;
}

.hero-contain .hero-sub-title {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 400;
    color: #fff;
}

.hero-contain .hero-title {
    font-size: 52px;
    font-weight: 700;
    margin-top: 15px;
    text-align: center;
    text-transform: capitalize;
    color: #fff;
}

.hero-contain .hero-pargraph {
    font-size: 20px;
    text-transform: capitalize;
    color: #fff;
    margin-top: 15px;
}

.btn-count {
    position: relative;
    padding: 20px 70px;
    border: 1px solid #e20712;
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    -webkit-font-smoothing: antialiased;
    background: #e20712;
    color: #fff;
}

.btn-count:hover {
    border: 1px solid transparent;
    background: transparent;
    color: #fff;
}

.line-1 {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    background-color: white;
    left: 0;
    bottom: 0;
}

.line-2 {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    background-color: white;
    left: 0;
    top: 0;
}

.line-3 {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    background-color: white;
    right: 0;
    top: 0;
}

.line-4 {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    background-color: white;
    right: 0;
    bottom: 0;
}

.btn-count:hover .line-1 {
    animation: move1 1500ms infinite ease;

}

.btn-count:hover .line-2 {
    animation: move2 1500ms infinite ease;
}

.btn-count:hover .line-4 {
    animation: move4 1500ms infinite ease;
}

.btn-count:hover .line-3 {
    animation: move3 1500ms infinite ease;
}

@keyframes move1 {
    0% {
        height: 100%;
        bottom: 0;
    }

    54% {
        height: 0;
        bottom: 100%;
    }

    55% {
        height: 0;
        bottom: 0;
    }

    100% {
        height: 100%;
        bottom: 0;
    }
}

@keyframes move2 {
    0% {
        width: 0;
        left: 0;
    }

    50% {
        width: 100%;
        left: 0;
    }

    100% {
        width: 0;
        left: 100%;
    }
}

@keyframes move3 {
    0% {
        height: 100%;
        top: 0;
    }

    54% {
        height: 0;
        top: 100%;
    }

    55% {
        height: 0;
        top: 0;
    }

    100% {
        height: 100%;
        top: 0;
    }
}

@keyframes move4 {
    0% {
        width: 0;
        right: 0;
    }

    55% {
        width: 100%;
        right: 0;
    }

    100% {
        width: 0;
        right: 100%;
    }
}

/*  */


.service-icon {
    position: relative;
    height: 90px;
    width: 80px;
    transition: all 0.5s ease-in;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    cursor: pointer;
}

.service-icon::after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: '';
    background: #a7a7a7;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%, 50% 0%, 50% 2px, 2px calc(25% + 2px * 0.49999999999999994), 2px calc(75% + 2px * -0.49999999999999994), 50% calc(100% - 2px), calc(100% - 2px) calc(75% + 2px * -0.49999999999999994), calc(100% - 2px) calc(25% + 2px * 0.49999999999999994), 50% 2px);
}

.service-icon:hover,
.service-icon:hover::after {
    transition: all 0.5s ease-out;
    background: #e20712;
}

.service-icon:hover .icon {
    transition: all 0.5s ease-out;
    color: #fff;
}

.service-icon .icon {
    position: absolute;
    z-index: 999;
    top: 50%;
    height: 30px;
    width: 30px;
    transition: all 0.5s ease-in;
    color: #a7a7a7;
    left: 50%;
    transform: translate(-50%, -50%);
}

.section-padding {
    padding-top: 100px;
}

.service-content h4 {
    font-size: 24px;
    cursor: pointer;
    font-weight: 700;
    text-transform: capitalize;
}

.service-content p {
    font-size: 20px;
    color: #a7a7a7;
    text-transform: capitalize;
}

.welcome-main {
    background-image: url('./assets/images/white-background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}

.welcome-main h4 {
    font-size: 45px;
    font-weight: 700;
    color: #000;
    text-transform: capitalize;
}

.welcome-main p {
    color: #000;
    font-size: 20px;
    margin-top: 20px;
    font-weight: 600;
}

.section-bottom {
    padding-bottom: 80px;
}

.read-more:hover {
    color: #000;
    font-weight: 600;
}

.read-more .line-1,
.read-more .line-2,
.read-more .line-3,
.read-more .line-4 {
    background-color: #000;
}

.title {
    font-size: 46px;
    font-weight: 700;
    text-transform: capitalize;
    text-align: center;
}

.product-slider-1 {
    background-image: url('../src/assets/images/product7.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 700px;
    width: 100%;
    position: relative;
}

.our-product-title {
    padding-top: 150px;
    position: absolute;
    right: 0;
    padding-right: 50px;
    width: 25%;
    float: right;
    text-align: end;
}

.our-product-title h2 {
    color: #fff;
    font-size: 42px;
    font-weight: 700;
}

.bg-overlay-product {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
}

.our-product-title p {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.5;
}

.our-product-title button {
    font-size: 20px;
    text-transform: capitalize;
    height: 56px;
    width: 170px;
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    transition: all 0.5s ease-out !important;
}

.our-product-title button:hover {
    background: #e20712;
    border: 1px solid #e20712;
    transition: all 0.5s ease-in !important;
}

.video-product img {
    height: 350px;
}

.scroll-header {
    box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.5);
}

.product-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.5s ease-out;
    background: rgb(0, 0, 0);
    cursor: pointer;
    opacity: .4;
}

.product-gallery-main:hover .product-overlay {
    background-color: transparent;
    transition: all 0.5s ease-in;
}

.product-gallery-main img {
    transition: all 0.5s ease-in-out;
}

.product-gallery-main:hover img {
    transform: scale(1.09);
    transition: all 0.5s ease-in-out;
}

.choose-us-card {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    margin: 22px 10px;
    height: 380px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.choose-us-card .icon-chooseUs,
.contact-us-card .contact-us-icon2,
.contact-us-card .contact-us-icon1,
.contact-us-card .contact-us-icon3 {
    font-size: 34px;
    text-align: center;
    margin: 10px auto 25px auto;
    height: 100px;
    background: #ffd3cd;
    width: 100px;
    border-radius: 50%;
    line-height: 85px;
}

.choose-us-card .icon-chooseUs svg,
.contact-us-card .contact-us-icon2,
.contact-us-card .contact-us-icon1 svg,
.contact-us-card .contact-us-icon3 svg {
    font-size: 46px;
    color: #e20712;
}

.choose-us-card h3,
.contact-us-card h6 {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
}

.choose-us-card p,
.contact-us-card p {
    font-size: 18px;
    color: #a7a7a7;
    font-weight: 600;
    text-align: center;
    margin-top: 15px;
}

.contact-us-card p a {
    color: #a7a7a7 !important;
}

.footer-section {
    padding: 50px;
    background-color: #000000DE;
}

.footer-section h4 {
    font-size: 24px;
    margin-bottom: 18px;
}

.footer-logo img {
    width: 200px;
}

.footer-quick-link ul li {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    position: relative;
}

.footer-quick-link ul li a {
    text-decoration: none;
    letter-spacing: 0.15em;
    display: inline-block;
    text-transform: capitalize;
    padding-bottom: 5px;
    position: relative;
}

.footer-quick-link ul li a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    background: #e20712;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}
.social-icon{
    gap: 10px;
}

.social-icon li {
    background: #e20712;
    margin: 0;
    height: 32px;
    width: 32px;
    text-align: center;
}

.social-icon li svg {
    color: #fff;
    font-size: 18px;
    height: 32px;
    line-height: 18px;
}

.footer-quick-link ul li:hover a {
    color: #e20712 !important;
}

.footer-quick-link ul li a:hover:after {
    width: 100%;
    left: 0;
}

.social-main-icon {
    font-size: 22px !important;
}

.newsletter-input {
    width: 100%;
    height: 40px;
    padding: 0 20px;
}

.newsletter-input:focus-visible {
    outline: none !important;
}

.newsletter-icon {
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    text-align: center;
    line-height: 32px;
    background: #e20712;
    cursor: pointer;
}

.email-news-icon {
    font-size: 24px;
}

.footer-about p {
    font-size: 14px;
    color: #fff;
}

.element-section {
    background: url('../src/assets/images/breadcumb-img.jpg');
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    position: relative;
}

.element-bg-overlay {
    background-color: #000;
    opacity: 0.6;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.element-title {
    position: absolute;
    bottom: 30px;
    right: 50px;
}

.element-title h3 {
    font-size: 25px;
    color: #fff;
    text-transform: uppercase;
}

/*  */
.about-contain-main {
    margin-bottom: 70px;
}

.about-contain-main h1 {
    font-size: 30px;
    margin-bottom: 10px;
    font-weight: 700;
    text-transform: uppercase;
}

.about-contain-main p {
    font-size: 16px;
    color: #7a7a7a;
}

.page-title {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
}

/*  */
.contact-us-card {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    margin: 22px 10px;
    height: 300px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.contact-us-card .contact-us-icon1 svg {
    font-size: 36px;
}

.product-contain-main .product-contain-img {
    height: 250px;
}

.product-contain-main .product-contain p {
    font-size: 18px;
    color: #000;
    font-weight: 600;
}

.product-button {
    height: 50px;
    padding: 0 35px;
    border-radius: 12px;
    background: #e20712;
    font-size: 16px;
    color: #fff;
    border: 1px solid #e20712;
    transition: all 0.5s ease-in-out;
}

.toggle-product-button{
    height: 40px;
        padding: 10px;
        gap: 10px;
        width:fit-content;
        align-items: center;
        display: flex;
        border-radius: 12px;
        background: #e20712;
        font-size: 16px;
        color: #fff;
        border: 1px solid #e20712;
        transition: all 0.5s ease-in-out;
}

.product-button .download-icon {
    font-size: 24px;
}

.product-button:hover {
    background: #bc0c15;
    transition: all 0.5s ease-in-out;
    border: 1px solid #bc0c15;
}

.menu-list {
    justify-content: center;
}



.drop_container {
    overflow: hidden;
    max-height: 0;
    width: 250px;
    background-color: #fff;
}

.drop_container.show {
    position: absolute;
    z-index: 99;
    max-height: 350px;
    padding: 10px;
    top: 80px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transition: max-height 0.3s ease-in;
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.drop_container.hide {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;
}

.drop_container ul li {
    /* padding: 5px 20px !important; */
}

.toggle-list-unstyled{
    gap: 10px;
    margin-left: 6px;
        display: flex;
        flex-direction: column;
}

.arrow-container {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
}

.arrow-1 {
    width: 100px;
    height: 100px;
    background: #e20712;
    opacity: 0.5;
    border-radius: 50%;
    position: absolute;
}

.arrow-2 {
    width: 60px;
    height: 60px;
    background: #e20712;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
    display: table;
}

.arrow-2:before {
    width: 52px;
    height: 52px;
    content: "";
    border: 2px solid #bc0c15;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    bottom: 3px;
    right: 3px;
    left: 3px;
}

.arrow-2 svg {
    display: flex;
    height: 30px;
    width: 30px;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 100%;
    padding: 15px;
    width: 100%;
}

/* Custom Animate.css */

.animated.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
}

@-webkit-keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.4, .4, .4);
        transform: scale3d(.4, .4, .4);
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.4, .4, .4);
        transform: scale3d(.4, .4, .4);
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
    }
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn  ;
    animation-play-state:running;

}


/*  */

@media (max-width:1500px) {
    .header-sticky ul li {
        font-size: 18px;
    }
}

@media (max-width:1400px) {
    .header-sticky ul li {
        font-size: 16px;
    }

    .menu-list li {
        padding: 0 10px;
    }
    
    .our-product-title {
        width: 50%;
    }
}

@media (max-width:1200px) {
    .menu-list {
        justify-content: end;
    }

    .header-sticky ul li {
        font-size: 14px;
    }

    .product-contain-main .product-contain p {
        font-size: 16px;
    }
}

@media (max-width:990px) {
    .video-product img {
        height: 220px;
    }

    .hero-contain .hero-title {
        font-size: 40px;
    }

    .hero-contain .hero-sub-title,
    .hero-contain .hero-pargraph {
        font-size: 16px;
    }

    .service-content h4,
    .choose-us-card h3,
    .contact-us-card h6 {
        font-size: 20px;
    }

    .service-content p,
    .welcome-main p,
    .choose-us-card p,
    .contact-us-card p {
        font-size: 16px;
    }

    .title {
        font-size: 40px;
    }

    .service-icon {
        height: 80px;
        width: 70px;
    }

    .service-icon .icon {
        height: 27px;
        width: 27px;
    }

    .btn-count {
        padding: 15px 56px;
        font-size: 14px;
    }

    .hero-bg,
    .hero-bg .bg-overlay {
        height: 600px;
    }

    .section-bottom {
        padding-bottom: 50px;
    }

    .section-padding {
        padding-top: 60px;
    }

    .welcome-main h4 {
        font-size: 38px;
    }

    .choose-us-card .icon-chooseUs,
    .contact-us-card .contact-us-icon2,
    .contact-us-card .contact-us-icon1,
    .contact-us-card .contact-us-icon3 {
        height: 90px;
        width: 90px;
        line-height: 80px;
    }

    .choose-us-card .icon-chooseUs svg,
    .contact-us-card .contact-us-icon2,
    .contact-us-card .contact-us-icon1 svg,
    .contact-us-card .contact-us-icon3 svg {
        font-size: 38px;
    }
}

@media (max-width:768px) {
    .header-sticky {
        height: 80px;
    }

    .main {
        padding-top: 80px;
    }

    .toggle-icon svg {
        font-size: 24px;
    }

    .hero-contain .hero-sub-title {
        font-size: 16px;
    }

    .hero-contain .hero-title {
        font-size: 38px;
        margin-top: 15px;
    }

    .hero-contain .hero-pargraph {
        font-size: 14px;
        margin-top: 15px;
    }

    .btn-count {
        padding: 10px 50px;
        font-size: 14px;
    }

    .hero-bg,
    .product-slider-1 {
        height: 500px;
    }

    .hero-bg .bg-overlay {
        height: 500px;
    }

    .section-padding {
        padding-top: 50px;
    }

    .section-bottom {
        padding-bottom: 25px;
    }

    .service-icon {
        height: 65px;
        width: 60px;
    }

    .service-icon .icon {
        height: 24px;
        width: 24px;
    }

    .service-content h4,
    .our-product-title p {
        font-size: 18px
    }

    .service-content p {
        font-size: 14px;
    }

    .welcome-main h4,
    .title,
    .our-product-title h2 {
        font-size: 36px;
    }

    .welcome-main p {
        font-size: 16px;
    }

    .our-product-title {
        width: 80%;
    }

    .our-product-title button {
        font-size: 16px;
        height: 48px;
        width: 160px;
    }

    .footer-section {
        padding: 35px;
    }

    .video-product img {
        height: 300px;
    }

    .product-button {
        margin: 10px;

    }

    .arrow-1 ,.arrow-container {
        height: 60px;
        width: 60px;
    }

    .arrow-2 {
        height: 45px;
        width: 45px;
        top: 8px;
        left: 8px;
    }

    .arrow-2 svg {
        padding: 8px;
    }

    .arrow-2:before {
        width: 37px;
        height: 37px;
        right: 4px;
        left: 4px;
    }
}


@media (max-width:575px) {
    .product-button {
        width: 100%;
        margin-left: 0px !important;
        margin: 5px;
        margin-right: 0px !important;
    }
}

@media (max-width:520px) {
    .footer-section {
        padding: 15px;
    }
}

@media (max-width:475px) {
    .hero-contain .hero-title {
        font-size: 26px;
        margin-top: 7px;
    }

    .contact-us-card {
        height: 230px;
    }

    .hero-contain .hero-sub-title {
        font-size: 13px;
    }

    .hero-contain .hero-pargraph,
    .service-content p,
    .welcome-main p,
    .choose-us-card p,
    .contact-us-card p,
    .about-contain-main p {
        font-size: 12px;
    }

    .hero-bg,
    .product-slider-1,
    .hero-bg .bg-overlay {
        height: 440px;
    }

    .our-product-title {
        padding-top: 70px;
    }

    .about-contain-main {
        margin-bottom: 40px;
    }

    .choose-us-card {
        height: 300px;
    }

    .service-content h4,
    .choose-us-card h3,
    .contact-us-card h6 {
        font-size: 16px;
    }

    .welcome-main h4,
    .title,
    .our-product-title h2,
    .about-contain-main h1 {
        font-size: 26px;
    }

    .choose-us-card .icon-chooseUs,
    .contact-us-card .contact-us-icon2,
    .contact-us-card .contact-us-icon1,
    .contact-us-card .contact-us-icon3 {
        height: 75px;
        width: 75px;
        line-height: 70px;
    }

    .choose-us-card .icon-chooseUs svg,
    .contact-us-card .contact-us-icon2,
    .contact-us-card .contact-us-icon1 svg,
    .contact-us-card .contact-us-icon3 svg {
        font-size: 30px;
    }
}

@media (max-width:375px) {
    .our-product-title {
        padding-top: 40px;
        width: 100%;
    }

    .product-slider-1 {
        height: 300px;
    }

    .welcome-main h4,
    .title,
    .our-product-title h2 {
        font-size: 22px;
    }

    .service-content h4,
    .product-contain-main .product-contain p,
    .our-product-title p {
        font-size: 14px;
    }

    .our-product-title button {
        font-size: 12px;
        height: 44px;
        width: 153px;
    }

    .element-section {
        height: 120px;
    }

    .element-title h3 {
        font-size: 18px;
    }

    .page-title {
        font-size: 22px;
    }

    .product-button {
        padding: 0 20px;
    }
}